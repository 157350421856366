<template lang="pug">
.brand-wrapper-left.brand-login-wrapper-left.d-none.d-md-flex.text-white.py-4.align-items-start(
  v-if="!whiteLabel"
  :class="[colSize, `brand-login-wrapper-left-${lang}`]"
)
  login-left-hu(v-if="lang === 'hu'")
  login-left-en(v-if="lang === 'en'")
</template>
<script>
  import LoginLeftHu from '@/components/Login/LoginLeftHu.vue';
  import LoginLeftEn from '@/components/Login/LoginLeftEn.vue';

  export default {
    components: { LoginLeftHu, LoginLeftEn },
    props: {
      whiteLabel: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      lang() {
        return this.$i18n.locale;
      },
      colSize() {
        return this.lang === 'en' ? 'col-xl-3 col-sm-4' : 'col-sm-4';
      },
    },
  };
</script>
