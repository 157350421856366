<template lang="pug">
.wrapper.brand-auth.brand-login
  .row.d-flex-1.align-items-stretch.m-0.justify-content-end
    login-left(:white-label="isWhitelabelDomain")
    .col-12.brand-wrapper-right.brand-login-wrapper-right(:class="colSize")
      .brand-auth-link.col-12.text-right.align-self-start.py-3(v-if="!isWhitelabelDomain")
        .font-weight-500.font-size-0--875.py-4
          span.pr-1 {{ $t('login.dontHaveAccount') }}
          router-link.brand-link.font-weight-500(
            :to="{ name: 'registration', params: { lang: $route.params.lang } }"
            tag="a"
          ) {{ $t('login.createOne') }}
      login-box(@success="loginSuccess")
</template>

<script>
  import LoginBox from '@/components/Login/LoginBox.vue';
  import LoginLeft from '@/components/Login/LoginLeft.vue';
  import guessLang from '@/mixins/guessLang';
  import whiteLabelling from '@/mixins/whiteLabelling';
  import { mapActions } from 'vuex';
  import { showInitialScreenByUserType } from '@/router/utils';
  import { isWhitelabelDomain } from '@/config/url';
  import authCheckMixin from '@/mixins/auth-check';
  import { pushDataLayer } from '@/util';

  export default {
    name: 'Login',
    components: { LoginBox, LoginLeft },
    mixins: [guessLang, whiteLabelling, authCheckMixin],
    data() {
      return {
        email: '',
        password: '',
      };
    },
    computed: {
      lang() {
        return this.$i18n.locale;
      },
      colSize() {
        if (!this.isWhitelabelDomain) {
          return this.lang === 'en' ? 'col-xl-9 col-md-8' : 'col-md-8';
        }
        return '';
      },
    },
    async created() {
      const isRedirected = await this.verifyUserAuthAndViewport();
      if (isRedirected) {
        return;
      }
      if (!isWhitelabelDomain()) {
        document.title = this.$t('titles.login');
      }

      const { query } = this.$route;
      this.language = this.guessLanguage();
      if (!this.$route.params.lang) {
        await this.$router.replace({ name: 'login', params: { lang: this.language }, query });
        this.$i18n.locale = this.language;
        return;
      }

      if (localStorage) {
        await this.autoLogin();
      }

      this.getWhiteLabelSettings();
      this.setWLAssets();
    },
    methods: {
      ...mapActions(['autoLogin']),
      async loginSuccess(login) {
        this.$notify({
          type: 'success',
          text: this.$t('notifications.loginSuccess'),
        });

        await showInitialScreenByUserType(login?.accountType);
        pushDataLayer({ event: 'login' });
      },
    },
  };
</script>
