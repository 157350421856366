import { showInitialScreenByUserType } from '@/router/utils';
import iosViewportFix from '@/mixins/iosViewportFix';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [iosViewportFix],
  computed: {
    ...mapState(['account']),
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    async verifyUserAuthAndViewport() {
      this.modifyViewportIfNeeded();
      // if the user navigates back after the registration it will redirect the user.
      if (this.isLoggedIn()) {
        await showInitialScreenByUserType(
          this.account.login?.accountType || this.account.type || 'normal',
        );
        return true;
      }
      return false;
    },
  },
};
