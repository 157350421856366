<template lang="pug">
.ai-bg
  .ai-title The future of CRO is here
  img.ai-logo(:src="require('@/assets/admin/svg/optimonk-ai-logo.svg')")
  om-button.ai-button(@click="aiPage") Learn more
</template>
<script>
  export default {
    methods: {
      aiPage() {
        window.open('https://www.optimonk.com/ai');
      },
    },
  };
</script>
<style lang="sass">
  @import '../../sass/variables/_colors.sass'

  .brand-login-wrapper-left-en
    padding-top: 0 !important
  .ai-bg
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 30px
    background-image: url('~@/assets/admin/img/login-ai.png')
    background-color: #fff
    background-size: cover
    z-index: 1
    width: 100%

    .ai-logo
      width: 95%
      padding-left: 1.5vw
    .ai-title
      text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.10)
      font-family: Roboto
      font-size: 18px
      font-style: normal
      font-weight: 700
      line-height: 20px
      letter-spacing: 0.9px
      text-transform: uppercase
      text-align: center
    .ai-button
      display: inline-flex
      padding: 12px 20px
      justify-content: center
      align-items: center
      gap: 8px
      border-radius: 3px
      border: 1px solid $om-gray-300
      color: #FFF
      text-align: center
      font-feature-settings: 'clig' off, 'liga' off
      font-family: Roboto
      font-size: 16px
      font-style: normal
      font-weight: 500
      line-height: 28px
      &:hover
        text-decoration: none
</style>
