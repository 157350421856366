<template lang="pug">
AiLogin
</template>
<script>
  import AiLogin from '@/components/Login/AiLogin.vue';

  export default {
    components: { AiLogin },
  };
</script>
