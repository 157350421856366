const originalViewportContent = 'width=device-width,initial-scale=1.0';

export default {
  methods: {
    isIOSSafari() {
      const navigator = window.navigator;
      if (!navigator) return false;
      const userAgent = navigator.userAgent;
      if (!userAgent) return false;
      const ios = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
      return ios && userAgent.match(/Safari/i);
    },
    getViewport() {
      return document.querySelector('head meta[name="viewport"]');
    },
    modifyViewportIfNeeded() {
      const viewport = this.getViewport();
      if (this.isIOSSafari() && viewport) {
        viewport.setAttribute('content', `${originalViewportContent},maximum-scale=1.0`);
      }
    },
  },
  beforeDestroy() {
    this.modifyViewportIfNeeded();
  },
};
